const singleRequestButton = document.getElementById("send-single-request");
const burstRequestButton = document.getElementById("send-burst-requests");
const outputElement = document.getElementById("app");

function randomId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

function createOrUpdateOutput(id, status, message) {
    const output = document.getElementById(id) || document.createElement("div");
    output.id = id;
    output.style.padding = "8px";
    const border = status === "success" ? "green" : status === "failure" ? "red" : "blue";
    const background = status === "success" ? "lightgreen" : status === "failure" ? "lightcoral" : "lightblue";
    output.style.border = `2px solid ${border}`;
    output.style.backgroundColor = background;
    output.innerText = message;
    if (!outputElement.contains(output)) {
        outputElement.appendChild(output);
    }
}

async function sendRequests(n) {
    for (let i = 0; i < n; i++) {
        const id = randomId();
        console.log(id)
        createOrUpdateOutput(id, 'pending', "Sending request...");
        fetch("/parties/main/room").then(async (res) => {
            if (res.ok) {
                createOrUpdateOutput(id, 'success', await res.text());
            } else {
                createOrUpdateOutput(id, 'failure', await res.text());
            }
        })
    }
}

singleRequestButton.addEventListener("click", async () => {
    await sendRequests(1);
})

burstRequestButton.addEventListener("click", async () => {
    await sendRequests(10);
})